<template>
  <v-container fluid class="content-wrapper">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body ml-1">
          <!-- Products sell and New Orders -->
          <div class="row match-height">
            <div class="col-12">
              <div class="d-flex flex-row justify-start align-center">
                <h4 class="fw-600 mb-0">{{ $t("SigninPromotion.promotiontype") }}</h4>
                <v-btn
                  icon
                  class="ml-3"
                  @click="initAll()"
                  :class="loadingData && 'animate__spining'"
                  :disabled="loadingData"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <div v-if="loadingData" class="mt-2">
            <v-skeleton-loader
              type="article, divider, article, divider, article"
            ></v-skeleton-loader>
          </div>

          <div v-if="!loadingData" class="row mt-2">
            <div class="col-12">
              <h5 class="fw-600">{{ $t("SigninPromotion.promotiongroupprice") }}</h5>
              <h6 class>{{ $t("SigninPromotion.label_promotiongroupprice") }}</h6>
            </div>

            <template v-for="item in itemsCountryPromotionType">
              <div class="col-12 mt-1" :key="item._id">
                <div class="bg-white border hover-grey">
                  <div class="card-body">
                    <div class="row p-1">
                      <div
                        class="col-lg-1 col-md-1 col-sm-12 col-12 d-flex align-center justify-center"
                      >
                        <v-icon x-large :color="item.iconcolor">
                          {{ item.iconname }}
                        </v-icon>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                        <h4 class="fw-600">{{ item.name }}</h4>
                        <span class="font-medium-1">{{ item.detail }}</span>
                      </div>
                      <div class="col-lg-3 col-md-2 col-sm-12 col-12">
                        <span class="font-medium-1">
                          {{ $t("SigninPromotion.alwaysuse") }} ({{
                            $t("SigninPromotion.candisabledmax")
                          }}
                          {{ item.close_per_year }}
                          {{ $t("SigninPromotion.daysperyear") }})
                        </span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                        <span class="font-medium-1">{{ item.desciption }}</span>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-12 col-12">
                        <v-btn
                          depressed
                          dark
                          color="#666ee8"
                          class="btn-min-width"
                          style="text-transform: capitalize !important"
                          @click="gotoAddPromotionFunc(item.linkName, item._id)"
                        >
                          <span class="textstyle">
                            {{ $t("SigninPromotion.addpromotion") }}
                          </span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div v-if="!loadingData" class="row pt-3">
            <div class="col-12 mb-1">
              <h5 class="fw-600">{{ $t("SigninPromotion.promotion") }}</h5>
              <h6 class>{{ $t("SigninPromotion.label_promotion") }}</h6>
            </div>
            <template v-for="(item, index) in itemsPromotionType">
              <div
                v-if="item.type !== 'advance_booking'"
                class="col-12"
                :class="index + 1 !== itemsPromotionType.length ? 'my-0 py-0' : 'pt-0'"
                :key="item._id"
              >
                <div
                  class="bg-white border-left border-right border-top hover-grey"
                  :class="index + 1 == itemsPromotionType.length && 'border-bottom'"
                >
                  <div class="card-body">
                    <div class="row p-1">
                      <div
                        class="col-lg-1 col-md-1 col-sm-12 col-12 d-flex align-center justify-center"
                      >
                        <v-icon x-large :color="item.iconcolor">
                          {{ item.iconname }}
                        </v-icon>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                        <h4 class="fw-600">{{ item.name }}</h4>
                        <span class="font-medium-1">{{ item.detail }}</span>
                      </div>
                      <div class="col-lg-3 col-md-2 col-sm-12 col-12">
                        <span
                          v-if="item.booking_date && item.stay_date"
                          class="font-medium-1"
                        >
                          {{ $t("SigninPromotion.availableperiods") }}
                          <br />
                          {{ moment(item.booking_date.start).format("DD MMM YYYY") }}
                          -
                          {{ moment(item.booking_date.end).format("DD MMM YYYY") }}
                          <br />
                          {{ $t("SigninPromotion.availableperiod") }}
                          <br />
                          {{ moment(item.stay_date.start).format("DD MMM YYYY") }}
                          -
                          {{ moment(item.stay_date.end).format("DD MMM YYYY") }}
                        </span>
                        <span v-else class="font-medium-1">
                          {{ $t("SigninPromotion.anyday") }}
                        </span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                        <span class="font-medium-1">{{ item.desciption }}</span>
                      </div>
                      <div class="col-lg-2 col-md-3 col-sm-12 col-12">
                        <v-btn
                          depressed
                          dark
                          color="#666ee8"
                          class="btn-min-width"
                          style="text-transform: capitalize !important"
                          @click="gotoAddPromotionFunc(item.linkName, item._id)"
                        >
                          <span class="textstyle">
                            {{ $t("SigninPromotion.addpromotion") }}
                          </span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninPromotionTypeScreen",
  metaInfo: {
    title: "Promotion Type",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    propertyData: null,
    value_element: "",
    itemsPromotionType: [],
    itemsCountryPromotionType: [],
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.initPromotionType();
      self.initPropertyData();
      setTimeout(() => {
        self.loadingData = false;
      }, 1500);
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    async initPromotionType() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.itemsPromotionType = [];
      var temp = [];
      var tempLimit = [];
      var tempType = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/setting/promotion", {
          headers: {
            Authorization: self?.userToken,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          temp = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        temp?.map((el) => {
          if (el.type == "country_limit")
            tempLimit.push({
              ...el,
              iconname: "mdi-map",
              iconcolor: "#1e9ff2",
              linkName: "SigninPromotionTypeNationScreen",
            });
          else
            tempType.push({
              ...el,
              iconname:
                el?.type == "basic"
                  ? "mdi-map-marker-outline"
                  : el?.type == "free"
                  ? "mdi-calendar-blank-outline"
                  : "mdi-cellphone",
              iconcolor:
                el?.type == "basic"
                  ? "#28d094"
                  : el?.type == "free"
                  ? "#ff9149"
                  : "#666ee8",
              linkName:
                el?.type == "basic"
                  ? "SigninPromotionTypeBasicScreen"
                  : el?.type == "free"
                  ? "SigninPromotionTypeFreeScreen"
                  : "",
            });
        });
        self.itemsPromotionType = tempType;
        self.itemsCountryPromotionType = tempLimit;
        // console.log(self.itemsPromotionType, self.itemsCountryPromotionType);
      }
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // console.log(temp);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    gotoAddPromotionFunc(linkName, promotionTypeId) {
      const link = {
        name: linkName,
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          promotionTypeId,
          promotionId: "",
        },
      };
      EventBus.$emit("changePathname", link);
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/promotion.css";

.v-menu__content {
  max-width: 290px !important;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
